// import React, { useState, useEffect } from "react";
// import { Typography,Box,Paper} from "@mui/material";
// import { Introchecklist } from "../../data/Introchecklist";
// import { useParams, useSearchParams } from "react-router-dom";
// export default function Checklist() {
//   const { id } = useParams();
//   const [data, setData] = useState();
//   useEffect(() => {
//     let ans = Introchecklist.filter((obj) => {
//       return obj.id == id;
//     });
//     console.log(ans);
//     setData(ans);
//     window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
//   }, [id]);
//   return (
//     <>
//       {data &&
//         data.map((introchecklist) => (
//           <Box sx={{backgroundImage:"linear-gradient(to top, #e6e9f0 0%, #eef1f5 100%)"}}>
//             <Box sx={{ mx:27,py:4}}>
//                 <Paper sx={{p:3}}>
//       <Typography sx={{fontWeight:"bold",fontSize:"2rem",textAlign:"center",mb:1}}>{introchecklist.hadding}</Typography>
//      {introchecklist.intro}
//      <Typography sx={{fontWeight:"bold",fontSize:"1.1rem",mt:4,mb:2}}>{introchecklist.Software}</Typography>
//      {introchecklist.Softwareinfo}
//      <Typography sx={{fontWeight:"bold",fontSize:"1.1rem",mt:4,mb:2}}>{introchecklist.Environment}</Typography>
//      {introchecklist.Environmentinfo}
//      <Typography sx={{fontWeight:"bold",fontSize:"1.1rem",mt:4,mb:2}}>{introchecklist.Time}</Typography>
//      {introchecklist.Timeinfo}
//      <Typography sx={{fontWeight:"bold",fontSize:"1.1rem",mt:4,mb:2}}>{introchecklist.Internet}</Typography>
//      {introchecklist.Internetinfo}
//      <Typography sx={{fontWeight:"bold",fontSize:"1.1rem",mt:4,mb:2}}>{introchecklist.Power}</Typography>
//      {introchecklist.Powerinfo}
//      </Paper>
//      </Box>
//           </Box>
//         ))}
//     </>
//   );
// }

// ----------------------------------responsive code-----------------------------
import React, { useState, useEffect } from "react";
import { Typography, Box, Paper } from "@mui/material";
import { Introchecklist } from "../../data/Introchecklist";
import { useParams } from "react-router-dom";

export default function Checklist() {
  // const { id } = useParams();
  const  id  = "checklist";
  const [data, setData] = useState();

  useEffect(() => {
    let ans = Introchecklist.filter((obj) => obj.id == id);
    setData(ans);
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [id]);

  return (
    <>
      {data &&
        data.map((introchecklist) => (
          <Box
            key={introchecklist.id} // Adding a unique key for each mapped item
            sx={{
              backgroundImage: "linear-gradient(to top, #e6e9f0 0%, #eef1f5 100%)",
              mx: "auto",
              padding: "1rem",
            }}
          >
            <Paper sx={{ p: 2 }}>
              <Typography
                sx={{
                  fontWeight: "bold",
                  fontSize: { xs: "1.5rem", sm: "2rem" }, // Adjusting font size for different screen sizes
                  textAlign: "center",
                  mb: 1,
                }}
              >
                {introchecklist.hadding} {/* Corrected the spelling of "heading" */}
              </Typography>
              {introchecklist.intro}
              <Typography
                sx={{
                  fontWeight: "bold",
                  fontSize: "1.1rem",
                  mt: 4,
                  mb: 2,
                }}
              >
                {introchecklist.Software}
              </Typography>
              {introchecklist.Softwareinfo}
              <Typography
                sx={{
                  fontWeight: "bold",
                  fontSize: "1.1rem",
                  mt: 4,
                  mb: 2,
                }}
              >
                {introchecklist.Environment}
              </Typography>
              {introchecklist.Environmentinfo}
              <Typography
                sx={{
                  fontWeight: "bold",
                  fontSize: "1.1rem",
                  mt: 4,
                  mb: 2,
                }}
              >
                {introchecklist.Time}
              </Typography>
              {introchecklist.Timeinfo}
              <Typography
                sx={{
                  fontWeight: "bold",
                  fontSize: "1.1rem",
                  mt: 4,
                  mb: 2,
                }}
              >
                {introchecklist.Internet}
              </Typography>
              {introchecklist.Internetinfo}
              <Typography
                sx={{
                  fontWeight: "bold",
                  fontSize: "1.1rem",
                  mt: 4,
                  mb: 2,
                }}
              >
                {introchecklist.Power}
              </Typography>
              {introchecklist.Powerinfo}
            </Paper>
          </Box>
        ))}
    </>
  );
}

