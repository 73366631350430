import React, { useEffect, useState } from "react";
import {
  Typography,
  Button,
  Box,
  Paper,
  Grid,
  TextField,
  MenuItem,
  Container,
  CardMedia,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormLabel,
  Select,
  OutlinedInput,
  InputLabel,
  FormControl,
  Chip,
  Autocomplete,
  Checkbox,
} from "@mui/material";

import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import clientConfig from "./Doc/client-Config";
const nobOptions = (selectedDomain) => {
  let tempVal = [];
  if (selectedDomain === "DS")
    tempVal = ["Data Science", "Artificial Intelligence", "Machine Learning"];
  else if (selectedDomain === "PM") tempVal = ["Product Management"];
  else if (selectedDomain === "DT") tempVal = ["Digital Transformation"];
  else if (selectedDomain === "PMT") tempVal = ["Project Management"];
  else if (selectedDomain === "FT") tempVal = ["Investment Banking", "Fintech", "Core Finance"];

  return tempVal;
};

let DomainOpt = [
  {
    value: "DS",
    label: "Data Science",
  },
  {
    value: "PM",
    label: "Product Management",
  },
  {
    value: "DT",
    label: "Digital Transformation",
  },
  {
    value: "PMT",
    label: "Project Management",
  },
  {
    value: "FT",
    label: "Finance",
  },
];

const PreOnboarding = () => {
  const [user, setUser] = useState("");
  const [backopen, setBackopen] = useState(false);
  const [val, setVal] = useState([]);
  const [subtype, setSubtype] = useState([]);
  const LoaderClose = () => {
    setBackopen(false);
  };
  const LoaderOpen = () => {
    setBackopen(true);
  };
  // Use for Navigate on Previous
  let navigate = useNavigate();
  useEffect(() => {
    // console.log(auth)
    var user_id = localStorage.getItem("user_id");

    setUser(user_id);
  }, []);

  const [data, setData] = useState({
    firstname: "",
    lastname: "",
    email: "",
    payout: "",
    vendor: "",
    payout_option: "",
    isGst: 0,
  });

  const datahandleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const submitForm = async (e) => {
    e.preventDefault();
    const siteUrl = clientConfig.siteUrl;
    LoaderOpen();

    var newpayout_option = data.payout_option;
    if (newpayout_option == "Accredian") {
      var newpayout = data.payout;
      var newvendor = data.vendor;
      if (newpayout == "") {
        toast.warn("Please fill Pay out per hour filed", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        LoaderClose();
        setData({
          firstname: "",
          lastname: "",
          email: "",
          payout: "",
          vendor: "",
          payout_option: "",
          isGst: 0,
        });

        setVal([]);
        setSubtype([]);
        inhandleClick();
      } else {
        const options = {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
          },
        };
        const sandData = {
          firstname: data.firstname,
          lastname: data.lastname,
          email: data.email,
          vertical: val,
          module: subtype,
          payout: data.payout,
          vendor: data.vendor,
          payout_option: data.payout_option,
          isGst: data.isGst,
        }; 
        axios
          .post(
            `${process.env.REACT_APP_API_ENDPOINT_NODE_PRODUCTION}/faculty-registration/pre-new-onboarding`,
            JSON.stringify(sandData),
            options
          )
          // await axios
          //   .post(`$(siteUrl}/pre-new-onboarding`,
          //     JSON.stringify(sandData),
          //     options
          //   )
          .then((result) => {
            if (result.data.status == "200") {
              toast.success(
                "Successful, Both Onboarding mails are sent to the Instructor",
                {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "colored",
                }
              );
              LoaderClose();
              setData({
                firstname: "",
                lastname: "",
                email: "",
                payout: "",
                vendor: "",
                payout_option: "",
                isGst: 0,
              });

              setVal([]);
              setSubtype([]);
              inhandleClick();
            } else if (result.data.status == "300") {
              toast.error(
                "Something went wrong, No mails are sent to the instructor",
                {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "colored",
                }
              );
              LoaderClose();
              setData({
                firstname: "",
                lastname: "",
                email: "",
                payout: "",
                vendor: "",
                payout_option: "",
                isGst: 0,
              });

              setVal([]);
              setSubtype([]);
              inhandleClick();
            } else if (result.data.status == "400") {
              toast.warn(
                "Something went wrong, Only Suchit mail is sent to the instructor",
                {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "colored",
                }
              );
              LoaderClose();
              setData({
                firstname: "",
                lastname: "",
                email: "",
                payout: "",
                vendor: "",
                payout_option: "",
                isGst: 0,
              });

              setVal([]);
              setSubtype([]);
              inhandleClick();
            } else if (result.data.status == "500") {
              toast.warn("Please fill all fields", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
              });
              LoaderClose();
              setData({
                firstname: "",
                lastname: "",
                email: "",
                vertical: "",
                payout: "",
                vendor: "",
                payout_option: "",
                isGst: 0,
              });

              setVal([]);
              setSubtype([]);
              inhandleClick();
            }
          });
        console.log(sandData);
      }
    } else {
      const options = {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
      };
      const sandData = {
        firstname: data.firstname,
        lastname: data.lastname,
        email: data.email,
        vertical: val,
        module: subtype,
        payout: data.payout,
        vendor: data.vendor,
        payout_option: data.payout_option,
        isGst: data.isGst,
      };
      axios
        .post(
          `${process.env.REACT_APP_API_ENDPOINT_NODE_PRODUCTION}/faculty-registration/pre-new-onboarding`,
          JSON.stringify(sandData),
          options
        )
        .then((result) => {
          if (result.data.status == "200") {
            toast.success(
              "Successful, Both Onboarding mails are sent to the Instructor",
              {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
              }
            );
            LoaderClose();
            setData({
              firstname: "",
              lastname: "",
              email: "",
              payout: "",
              vendor: "",
              payout_option: "",
              isGst: 0,
            });

            setVal([]);
            setSubtype([]);

            setInShown();
            setIsShown();
          } else if (result.data.status == "300") {
            toast.error(
              "Something went wrong, No mails are sent to the instructor",
              {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
              }
            );
            LoaderClose();
            setData({
              firstname: "",
              lastname: "",
              email: "",
              payout: "",
              vendor: "",
              payout_option: "",
              isGst: 0,
            });
            setVal([]);
            setSubtype([]);
            setInShown();
            setIsShown();
          } else if (result.data.status == "400") {
            toast.warn(
              "Something went wrong, Only Suchit mail is sent to the instructor",
              {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
              }
            );
            LoaderClose();
            setData({
              firstname: "",
              lastname: "",
              email: "",
              payout: "",
              vendor: "",
              payout_option: "",
              isGst: 0,
            });

            setVal([]);
            setSubtype([]);
            setInShown();
            setIsShown();
          } else if (result.data.status == "500") {
            toast.warn("Please fill all fields", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
            LoaderClose();
            setData({
              firstname: "",
              lastname: "",
              email: "",
              payout: "",
              vendor: "",
              payout_option: "",
              isGst: 0,
            });

            setVal([]);
            setSubtype([]);
            setInShown();
            setIsShown();
          }
        });
      console.log(sandData);
    }
  };
  if (user == null) {
    navigate(`/`);
  }
  const LogOut = () => {
    localStorage.removeItem("user_id");
    localStorage.clear();
    navigate(`/`);
  };
  const [inShown, setInShown] = useState(false);
  const inhandleClick = () => {
    setInShown((current) => !current);
    setIsShown();
  };
  const [isShown, setIsShown] = useState(false);
  const handleClick = () => {
    setIsShown((current) => !current);
    setInShown();
  };
  const currencies = [
    {
      value: "Select your Vendor",
      label: "Select your Vendor",
    },
    {
      value: "Awign",
      label: "Awign",
    },
  ];
  return (
    <>
      <ToastContainer />
      <Box display="flex">
        <Box>
          <CardMedia
            component="img"
            image="../accredian.webp"
            alt="green iguana"
            sx={{ width: 120, py: 2, ml: 5 }}
          />
        </Box>
        <Box sx={{ pt: 3, ml: 125 }}>
          <Button
            variant="contained"
            type="submit"
            sx={{
              mb: 0.3,

              float: "right",
              mr: 3,
              background: "#009ef7",
              borderRadius: "8px",
              textTransform: "none",
              "&:hover": {
                background: "#009ef7",
                borderRadius: "8px",
                textTransform: "none",
              },
            }}
            onClick={LogOut}
          >
            Logout
          </Button>
        </Box>
      </Box>
      <Container component="main" maxWidth="xs">
        <Paper
          sx={{
            boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px;",
            borderRadius: "10px",
            mb: 3,
          }}
        >
          <Box
            sx={{
              marginTop: 8,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              p: 3,
            }}
          >
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={backopen}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
            <form onSubmit={submitForm}>
              <Box>
                <Typography
                  sx={{
                    textAlign: "center",
                    fontSize: "1.4rem",
                    fontWeight: "bold",
                    mb: 2,
                  }}
                >
                  Faculty pre-new-onboarding
                </Typography>
                <Grid container>
                  <Grid item lg={12} sx={{ mb: 3 }}>
                    <TextField
                      id="First_Name"
                      label="First Name"
                      name="firstname"
                      fullWidth
                      type="text"
                      sx={{ pr: 3 }}
                      onChange={datahandleChange}
                      value={data.firstname}
                      required
                      onKeyPress={(e) => {
                        if (new RegExp(/[a-zA-Z]/).test(e.key)) {
                        } else e.preventDefault();
                      }}
                    />
                  </Grid>
                  <Grid item lg={12} sx={{ mb: 3 }}>
                    <TextField
                      id="Last_Name"
                      label="Last Name"
                      name="lastname"
                      fullWidth
                      type="text"
                      sx={{ pr: 3 }}
                      onChange={datahandleChange}
                      value={data.lastname}
                      required
                      onKeyPress={(e) => {
                        if (new RegExp(/[a-zA-Z]/).test(e.key)) {
                        } else e.preventDefault();
                      }}
                    />
                  </Grid>
                  <Grid item lg={12} sx={{ mb: 3 }}>
                    <TextField
                      id="E-mail"
                      label="E-mail"
                      name="email"
                      fullWidth
                      type="email"
                      sx={{ pr: 3 }}
                      onChange={datahandleChange}
                      value={data.email}
                      required
                      onKeyPress={(e) => {
                        if (new RegExp(/^[^<>]*$/).test(e.key)) {
                        } else e.preventDefault();
                      }}
                    />
                  </Grid>
                  <Grid item lg={12} sx={{ mb: 3 }}>
                    <Autocomplete
                      id="tags-standard"
                      freeSolo
                      filterSelectedOptions
                      options={DomainOpt}
                      onChange={(e, newValue) => {
                        if (newValue === null) {
                          setVal([]);
                          setSubtype([]);
                        } else {
                          setVal(newValue.value);
                          setSubtype([]);
                        }
                      }}
                      getOptionLabel={(option) => option.label}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          placeholder="Select your vertical"
                          label="Select your vertical"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item lg={12} sx={{ mb: 3 }}>
                    <Autocomplete
                      multiple
                      id="tags-standard"
                      freeSolo
                      value={subtype}
                      filterSelectedOptions
                      options={nobOptions(val)}
                      onChange={(e, attr) => setSubtype(attr)}
                      getOptionLabel={(option) => option}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label="Select your Module"
                        />
                      )}
                    />
                  </Grid>{" "}
                  <Grid item lg={12} sx={{ mb: 2 }}>
                    <FormLabel id="demo-radio-buttons-group-label">
                      Select your payout option
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="payout_option"
                      onChange={datahandleChange}
                      value={data.payout_option}
                      required
                    >
                      <FormControlLabel
                        value="Vendor"
                        control={
                          <Radio
                            sx={{
                              "& .MuiSvgIcon-root": {
                                fontSize: 22,
                              },
                            }}
                          />
                        }
                        label={
                          <Typography
                            sx={{
                              fontSize: "1rem",
                              marginBottom: "0.8rem !important",
                              pt: 2,
                            }}
                          >
                            Vendor{" "}
                          </Typography>
                        }
                        onChange={handleClick}
                      />

                      <FormControlLabel
                        value="Accredian"
                        control={
                          <Radio
                            sx={{
                              "& .MuiSvgIcon-root": {
                                fontSize: 22,
                              },
                            }}
                          />
                        }
                        label={
                          <Typography
                            sx={{
                              fontSize: "1rem",
                              marginBottom: "0.8rem !important",
                              pt: 2,
                            }}
                          >
                            Accredian{" "}
                          </Typography>
                        }
                        sx={{ ml: 15 }}
                        onChange={inhandleClick}
                      />
                    </RadioGroup>

                    {isShown && (
                      <TextField
                        id="outlined-select-currency"
                        select
                        label="Select your Vendor"
                        name="vendor"
                        value={data.vendor}
                        onChange={datahandleChange}
                        fullWidth
                        sx={{ mt: 2 }}
                      >
                        {currencies.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                    {inShown && (
                      <TextField
                        id="payout"
                        label="Pay out per hour"
                        name="payout"
                        fullWidth
                        type="text"
                        sx={{ pr: 3, mt: 2 }}
                        onChange={datahandleChange}
                        value={data.payout}
                        onKeyPress={(e) => {
                          if (new RegExp(/^[^<>]*$/).test(e.key)) {
                          } else e.preventDefault();
                        }}
                      />
                    )}
                  </Grid>
                  {/* Gst checkbox  */}
                  <Grid item lg={12} sx={{ mb: 3 }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="isGst"
                          checked={data.isGst === "1"}
                          onChange={(e) =>
                            setData({
                              ...data,
                              isGst: e.target.checked ? "1" : "0",
                            })
                          }
                        />
                      }
                      label="Add GST"
                    />
                  </Grid>
                  {/* Till here */}
                  <Grid item lg={12} sx={{ mb: 3 }}>
                    <center>
                      {" "}
                      <Button
                        variant="contained"
                        type="submit"
                        sx={{
                          textAlign: "center",
                          mr: 3,
                          background: "#009ef7",
                          borderRadius: "8px",
                          textTransform: "none",
                          "&:hover": {
                            background: "#009ef7",
                            borderRadius: "8px",
                            textTransform: "none",
                          },
                        }}
                      >
                        Submit
                      </Button>
                    </center>
                  </Grid>
                </Grid>
              </Box>
            </form>
          </Box>
        </Paper>
      </Container>
    </>
  );
};
export default PreOnboarding;
